import React, { Suspense } from 'react';

import styled from 'styled-components';
import {
  OrbitControls,
  Sphere,
  MeshDistortMaterial,
  Environment,
} from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { useTranslation } from 'react-i18next';
import SolanaModel from './SolanaModel';
import { Title, BodyLarge } from '../../../../components/typography';

const Section = styled.section`
  /* height: 100vh; */
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
  /* background: #4c366c; */
  background: black;
  padding: 4% 6%; //move this later
`;

const Container = styled.div`
  position: relative;
  height: 100vh;
  scroll-snap-align: center;
  width: 1400px;
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  flex: 4;
  position: relative;

  @media only screen and (max-width: 768px) {
    position: absolute;
    z-index: 0;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    opacity:0.7;
  }
`;

const Right = styled.div`
  flex: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  max-width: 800px;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;
const StyledParagraph = styled.p`
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Tokenomics = () => {
  const { t } = useTranslation();

  return (
    <Section id="home-tokenomics">
      <Container>
        <Left>
          <Canvas>
            <Suspense fallback={null}>
              <OrbitControls enableZoom={false} />
              <ambientLight intensity={1} />
              <directionalLight position={[3, 2, 1]} />

              <Environment preset="city"></Environment>
              <SolanaModel />
            </Suspense>
          </Canvas>
        </Left>
        <Right>
          <Title>{t('home.tokenomics.title')}</Title>

          <StyledParagraph>
            <BodyLarge>{t('home.tokenomics.paragraph1')}</BodyLarge>
          </StyledParagraph>

          <StyledParagraph>
            <BodyLarge style={{ fontWeight: 700, padding: '18px' }}>
              {t('home.tokenomics.paragraph2.bold')}
            </BodyLarge>
            <BodyLarge>{t('home.tokenomics.paragraph2.normal')}</BodyLarge>
          </StyledParagraph>

          <StyledParagraph>
            <BodyLarge style={{ fontWeight: 700, padding: '18px' }}>
              {t('home.tokenomics.paragraph3.bold')}
            </BodyLarge>
            <BodyLarge>{t('home.tokenomics.paragraph3.normal')}</BodyLarge>
          </StyledParagraph>
        </Right>
      </Container>
    </Section>
  );
};

export default Tokenomics;
