import React from 'react';
import styled from 'styled-components';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { enqueueSnackbar } from 'notistack';

import { ArrowRightIcon } from '../../components/icons';


const StyledCareersPage = styled.div`
  position: relative;
  text-align: center;
  min-height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  color: white;
  background: url('./assets/images/bg.jpeg');
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }

  .jobs-rows {
    margin-top: 50px;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;

  @media only screen and (max-width: 850px) {
    font-size: 2.4rem;
  }

  @media only screen and (max-width: 850px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  opacity: 0.75;
  padding: 6px;

  @media only screen and (max-width: 850px) {
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 850px) {
    font-size: 1rem;
  }
`;

const StyledJobRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  gap: 12px;
  margin: 20px auto;

  @media only screen and (max-width: 850px) {
    max-width: 75%;
  }

  .job-description {
    h3 {
      @media only screen and (max-width: 850px) {
        font-size: 0.95rem;
      }
      @media only screen and (max-width: 450px) {
        font-size: 0.85rem;
      }
    }

    p {
      opacity: 0.75;

      @media only screen and (max-width: 450px) {
        font-size: 0.9rem;
      }
    }
  }

  button {
    border-radius: 3px;
    opacity: 0.95;
    @media only screen and (max-width: 850px) {
      padding: 4px 8px;
    }

    @media only screen and (max-width: 450px) {
      padding: 2px 4px;
    }
  }
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 38px;
  background: #1e1e1e;
  border-radius: 12px;

  @media only screen and (max-width: 450px) {
    padding: 16px;
  }

  h2 {
    color: #ffffff;
    font-size: 1.8rem;
    margin-bottom: 20px;

    @media only screen and (max-width: 450px) {
      font-size: 1.5rem;
    }
  }

  label {
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;

    textarea,
    input[type='url'],
    input[type='file'],
    input {
      width: 100%;
      padding: 12px;
      margin-top: 8px;
      font-size: 1rem;
      color: #ffffff;
      background: #333333;
      border: 1px solid #444;
      border-radius: 8px;
      outline: none;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: #0056d2;
      }
    }

    textarea {
      resize: none;
      min-height: 100px;
    }
  }

  .submit-button {
    margin-top: 20px;
    padding: 12px 24px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #ffffff;
    background-color: #0056d2;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0043a0;
    }

    &:disabled {
      background-color: #777;
      cursor: not-allowed;
    }
  }
`;

const jobsConfig = [
  {
    id: 'marketing-manager',
    title: 'Marketing Manager',
    description:
      'You’ll create and run strategies to grow our brand and engage our audience.',
  },
  {
    id: 'community-manager',
    title: 'Community Manager',
    description:
      'You’ll engage with our community, answer questions, and keep users excited about our brand.',
  },
  {
    id: 'rust-developer',
    title: 'Rust Developer',
    description:
      'You’ll build fast, secure software and develop Solana programs using Rust.',
  },
  {
    id: 'cyber-security-engineer',
    title: 'Cybersecurity Engineer',
    description:
      'You’ll protect our systems by finding and fixing security risks.',
  },
];

const JobRow = ({
  title,
  description,
  setIsJobsModalOpen,
  setJobSelected,
}: any) => (
  <StyledJobRow>
    <div className="job-description">
      <h3>{title}</h3>
      <p>{description}</p>
    </div>

    <div className="job-button">
      <button
        onClick={() => {
          setJobSelected(title);
          setIsJobsModalOpen(true);
        }}
      >
        <ArrowRightIcon />
      </button>
    </div>
  </StyledJobRow>
);

export default function CareersPage() {
  const [isJobsModalOpen, setIsJobsModalOpen] = React.useState(false);
  const [jobSelected, setJobSelected] = React.useState('Marketing Manager');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [extraLinkUrl, setExtraLinkUrl] = React.useState('');
  const [attachment, setAttachment] = React.useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);

    enqueueSnackbar('Sending your application...', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      variant: 'info',
      autoHideDuration: 5000,
    });

    const formData = new FormData();
    formData.append('job', jobSelected);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('message', message);
    formData.append('extraLink', extraLinkUrl);
    if (attachment) {
      formData.append('attachment', attachment);
    }

    try {
      setIsJobsModalOpen(false);
      const response = await fetch(
        `https://formsubmit.co/${process.env.REACT_APP_RECEIVER_EMAIL}`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (response.ok) {
        enqueueSnackbar('Thank you for applying, we will contact you soon!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'success',
          autoHideDuration: 5000,
          style: { backgroundColor: '#2d8fd2' },
        });
      } else {
        throw new Error('Error sending form');
      }
    } catch (error) {
      enqueueSnackbar('Oops, something went wrong!', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        variant: 'error',
        autoHideDuration: 5000,
      });
    } finally {
      setIsSubmitting(false);
      setIsJobsModalOpen(false);
      setMessage('');
      setExtraLinkUrl('');
      setAttachment(null);
    }
  };

  return (
    <StyledCareersPage>
      <Modal
        open={isJobsModalOpen}
        onClose={() => setIsJobsModalOpen(false)}
        center
        styles={{
          modal: { backgroundColor: '#1e1e1e' },
          closeButton: { fill: 'white' },
        }}
      >
        <ModalForm onSubmit={handleSubmit}>
          <h2>Apply for {jobSelected}</h2>
          <label>
            First Name:
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Enter your first name"
              required
              disabled={isSubmitting}
            />
          </label>
          <label>
            Last Name:
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Enter your last name"
              required
              disabled={isSubmitting}
            />
          </label>
          <label>
            Short Message:
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Tell us why you're a good fit"
              required
              disabled={isSubmitting}
            />
          </label>
          <label>
          Relevant links:
            <input
              type="url"
              value={extraLinkUrl}
              onChange={(e) => setExtraLinkUrl(e.target.value)}
              placeholder="https://github.com//your-profile"
              required
              disabled={isSubmitting}
            />
          </label>
          <label>
            Attach Resume or CV:
            <input
              type="file"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setAttachment(e.target.files[0]);
                }
              }}
              accept=".pdf,.doc,.docx"
            />
          </label>
          <button
            className="submit-button"
            type="submit"
            disabled={isSubmitting}
          >
            Submit Application
          </button>
        </ModalForm>
      </Modal>

      <Title>Career Opportunities</Title>
      <Subtitle>
        Would you like to contribute to the BlueBag.app ecosystem?
      </Subtitle>
      <Subtitle>Explore our openings</Subtitle>

      <div className="jobs-rows">
        {jobsConfig.map((job) => (
          <JobRow
            key={job.id}
            title={job.title}
            description={job.description}
            setIsJobsModalOpen={setIsJobsModalOpen}
            setJobSelected={setJobSelected}
          />
        ))}
      </div>
    </StyledCareersPage>
  );
}
