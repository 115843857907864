import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 68px;
  margin: 0;
  line-height: 1;

  @media only screen and (max-width: 1000px) {
    font-size: 60px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 50px;
  }
`;

export const Heading1 = styled.h1`
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
`;

export const Heading2 = styled.h2`
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  margin: 0;
`;

export const Heading3 = styled.h3`
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
  margin: 0;
`;

export const Heading4 = styled.h4`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
`;

export const BodyLarge = styled.p`
  color: lightgray;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;

  @media only screen and (max-width: 1000px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 16px;
  }
`;

export const Body = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
`;

export const BodyBold = styled(Body)`
  font-weight: 700;
`;

export const BodyMedium = styled(Body)`
  font-weight: 500;
`;

export const BodySmall = styled(Body)`
  font-size: 14px;
`;

export const BodyOverline = styled.span`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const BodyOverlineSmall = styled(BodyOverline)`
  font-size: 10px;
`;
