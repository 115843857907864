import React, { Suspense } from 'react';
import styled from 'styled-components';

import LeftAnim from './LeftAnim';
import AuthForm from './AuthForm';

const StyledAuthPage = styled.div`
  position: relative;
  text-align: center;
  height: 100vh;
  overflow: hidden;
  color: white;
  background: url('./assets/images/bg.jpeg');
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  padding: 6%;

  display: flex;
  justify-content: center;
  align-items: center;

  .inner-wrapper {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5%;
  }

  /* .auth-form-section {
    flex: 1;
    height: 100%;
    padding: 4% 5%;
    overflow: hidden; // Ensures no section can overflow
  } */
`;

export default function AuthPage() {
  return (
    <StyledAuthPage>
      <div className="inner-wrapper">
        <LeftAnim />
        <AuthForm />
      </div>
    </StyledAuthPage>
  );
}
