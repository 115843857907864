import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    lng: 'en',
    fallbackLng: 'en',
    ns: ['home', 'common'], // name the components where you need translation ex. 'navbar'
    defaultNS: 'home',
    resources: {
      en: {
        home: require('../locales/en/home.json'),
        common: require('../locales/en/common.json'),
      },
      ch: {
        home: require('../locales/ch/home.json'),
        common: require('../locales/ch/common.json'),
      },
      br: {
        home: require('../locales/br/home.json'),
        common: require('../locales/br/common.json'),
      },

      sp: {
        home: require('../locales/sp/home.json'),
        common: require('../locales/sp/common.json'),
      },

      pt: {
        home: require('../locales/pt/home.json'),
        common: require('../locales/pt/common.json'),
      },

      fr: {
        home: require('../locales/fr/home.json'),
        common: require('../locales/fr/common.json'),
      },
      de: {
        home: require('../locales/de/home.json'),
        common: require('../locales/de/common.json'),
      },

      rs: {
        home: require('../locales/rs/home.json'),
        common: require('../locales/rs/common.json'),
      },
      jp: {
        home: require('../locales/jp/home.json'),
        common: require('../locales/jp/common.json'),
      },
      ar: {
        home: require('../locales/ar/home.json'),
        common: require('../locales/ar/common.json'),
      },
      hi: {
        home: require('../locales/hi/home.json'),
        common: require('../locales/hi/common.json'),
      },
      ukr: {
        home: require('../locales/ukr/home.json'),
        common: require('../locales/ukr/common.json'),
      },
    },

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    // detection: {
    //   order: ['localStorage', 'navigator'], // Detect from localStorage first, then browser settings
    //   caches: ['localStorage'], // Save the detected language in localStorage
    // },
  });

export default i18n;
