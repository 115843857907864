import React from 'react';
import styled from 'styled-components';

import BaseCard from '../../components/BaseCard';
import Button from '../../components/Button';

const StyledSignUpPage = styled.div`
  position: relative;
  text-align: center;
  height: 100vh;
  overflow: hidden;
  color: white;
  background: url('./assets/images/bg.jpeg');
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  padding: 6%;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 140px;
  }

  .step-text {
    color: black;

    h3 {
      font-size: 36px;
      font-weight: 900;
    }

    p {
      opacity: 0.5;
    }

    .code-resend {
      color: black;
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const PinInput = styled.input`
  width: 50px;
  height: 60px;
  font-size: 24px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: box-shadow 0.3s, border-color 0.3s;

  &:focus {
    border-color: #333;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }
`;

export default function SignUpPage() {
  return (
    <StyledSignUpPage>
      <BaseCard radius={12} width="100%" height="100%">
        <img src="/assets/images/bbag-logo.png" alt="logo" />

        <div className="step-text">
          <h3>Confirm your number</h3>
          <p>Please enter your verification code that you received by SMS.</p>
        </div>

        <InputWrapper>
          <PinInput type="text" />
          <PinInput type="text" />
          <PinInput type="text" />
          <PinInput type="text" />
        </InputWrapper>
        <div className="button-wrapper">
          <Button
            label="Continue"
            onClick={() => console.log('click')}
            width={'100%'}
          />
        </div>

        <p style={{ color: 'black', opacity: 0.65 }}>
          Didn't received the code?{' '}
          <span style={{ color: '#05c3e5', opacity: 1, fontWeight: 700 }}>
            Resend Code
          </span>
        </p>

        <p className="rights" style={{ color: 'black' }}>
          © 2024 Inc. All rights reserved.
        </p>
      </BaseCard>
    </StyledSignUpPage>
  );
}
