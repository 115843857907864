import Layout from '../Layout';

//public
import HomePage from '../../pages/Home';
import CareersPage from '../../pages/Careers';
import AuthPage from '../../pages/Auth';
import SignUpPage from '../../pages/SignUp';
import PrivacyPolicyPage from '../../pages/PrivacyPolicy';
import TermsAndConditionsPage from '../../pages/TermsAndConditions';
//private
import DashboardPage from '../../pages/Dashboard';
import SettingsPage from '../../pages/Settings';
import NotFoundPage from '../../pages/NotFound';

import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';

const PrivateRoute = ({ element }: any) => {
  const isAuthenticated = true;

  return isAuthenticated ? element : <Navigate to="/login" />;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: 'careers',
        element: <CareersPage />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'terms',
        element: <TermsAndConditionsPage />,
      },
      //change this
      {
        path: 'auth',
        element: <AuthPage />,
      },
      {
        path: 'signup',
        element: <SignUpPage />,
      },
      //PRIVATE
      {
        path: 'dashboard',
        element: <PrivateRoute element={<DashboardPage />}></PrivateRoute>,
      },
      {
        path: 'settings',
        element: <PrivateRoute element={<SettingsPage />}></PrivateRoute>,
      },
      //profile?
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);

export default function Router() {
  return <RouterProvider router={router} />;
}
