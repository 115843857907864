import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  padding: 60px;
  min-height: 400px;
  background: linear-gradient(180deg, #080215 0%, #1f1d1d 62%, #000 100%);
`;

const LeftContent = styled.div`
  flex: 1;
  color: #fff;
  text-align: left;
  margin-left: 40px;
`;

const Title = styled.h1`
  color: #00d9ff;
  font-size: 5rem;
  margin: 10px 0;
  opacity: 0.85;
`;

const Subtitle = styled.h2`
  color: #ccc;
  text-align: left;
  font-size: 17px;
  margin: 10px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;

  button {
    margin-right: 10px;
  }
`;

const Button = styled.button`
  padding: 10px 50px;
  background-color: #2983c1;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size:1.8rem;
  font-weight:700;

  &:hover {
    background-color: #00d9ff;
    color: #000;
  }
`;

const ImageWrapper = styled.div`
  opacity: 0.85;
  flex: 1;
`;

export default function CareersSection() {
  const navigate = useNavigate();

  return (
    <SectionWrapper>
      <LeftContent>
        <p>Careers At BlueBag.app</p>
        <Title>Work With Us</Title>
        <Subtitle>
          Explore remote-friendly, flexible opportunities and join our mission.
        </Subtitle>
        <ButtonContainer></ButtonContainer>
      </LeftContent>

      <Button onClick={() => navigate('/careers')}>View Opportunities</Button>
      {/* <ImageWrapper>
        <img
          src="/assets/images/logo-full-horizontal-text-white.png"
          alt="Logo BB"
          style={{ width: '60%', height: 'auto' }}
        />
      </ImageWrapper> */}
    </SectionWrapper>
  );
}
