import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Environment } from '@react-three/drei';
import BearModel from '../../../components/models/BearModel';
import { useTranslation } from 'react-i18next';
import getSocialNetworksDetails from '../../../utils/misc/getSocialNetworksDetails';

const socialsDetails = getSocialNetworksDetails();

const currentSocials = Object.values(socialsDetails).filter((social) => {
  const { displayName } = social;
  return (
    displayName == 'Telegram' ||
    displayName == 'Discord' ||
    displayName == 'Reddit'
  );
});

console.log('currentSocials');
console.log(currentSocials);

const StyledLeftAnim = styled.div`
  flex: 1;
  height: 100%;
  position: relative;

  h2 {
    font-size: 44px;
    opacity: 0.9;
  }

  .auth-anim-socials {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3%;
    opacity: 0.6;
  }
`;

const LeftAnim = () => {
  return (
    <StyledLeftAnim>
      <h2>The Future of social Network</h2>
      <Canvas>
        <Suspense fallback={null}>
          <OrbitControls enableZoom={false} />
          <ambientLight intensity={1} />
          <directionalLight position={[3, 2, 1]} />
          <Environment preset="city" />
          <BearModel scale={1.2} />
        </Suspense>
      </Canvas>

      <div className="auth-anim-socials">
        {Object.values(currentSocials).map((social: any) => {
          return (
            <a
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
              key={social.link}
            >
              <social.icon size={48} color={social.color} textColor="white" />
            </a>
          );
        })}
      </div>
    </StyledLeftAnim>
  );
};

export default LeftAnim;
