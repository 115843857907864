import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Title, BodyLarge } from '../../../../components/typography';

const Section = styled.section`
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  justify-content: center;

  background: url('./assets/images/bg.jpeg');
  background: #2d90d25f;
  padding: 0 6%; //move this later
`;

const Container = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  width: 1400px;
  display: flex;
  justify-content: space-between;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

const MissionVision = () => {
  const { t } = useTranslation();

  return (
    <Section id="home-mission">
      <Container>
        <Right>
          <Title>{t('home.mission.title')}</Title>

          <BodyLarge style={{ color: 'lightgray' }}>
            {t('home.mission.text')}
          </BodyLarge>
        </Right>
      </Container>
    </Section>
  );
};

export default MissionVision;
