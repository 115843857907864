import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import BaseCard from '../../../components/BaseCard';
import ToggleButton from '../../../components/Toggle';
import Button from '../../../components/Button';

const StyledAuthForm = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative; /* Ensure relative positioning for absolute children */

  img {
    max-width: 180px;
    opacity: 0.85;
  }
  .auth-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .greetings {
      color: black;
      text-align: center;
      margin-bottom: 32px;

      h3 {
        font-size: 36px;
        font-weight: 900;
      }

      p {
        opacity: 0.5;
      }
    }
  }

  //bottom
  .inputs {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 50%;
  }

  .rights {
    position: absolute;
    bottom: 20px;
    color: black;
    opacity: 0.65;
    font-size: 12px;
  }

  .button-wrapper {
    max-width: 75%;
    margin-top: 50px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  text-align: left;
`;

const TextInput = styled.input`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  outline: none;
  transition: box-shadow 0.3s;

  &:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }
`;

export default function AuthForm() {
  const [isLogIn, setIsLogIn] = useState(true);
  const handleToggle = () => setIsLogIn(!isLogIn);

  return (
    <StyledAuthForm>
      <BaseCard radius={12} width="100%" height="100%">
        <div className="auth-top">
          <img src="/assets/images/bbag-logo.png" alt="logo" />

          <div className="greetings">
            <h3>{isLogIn ? 'Welcome back' : 'Register'}</h3>
            <p>Please enter your details.</p>
          </div>

          <ToggleButton onToggle={handleToggle} isLogIn={isLogIn} />
        </div>

        {isLogIn ? <LoginInputs /> : <SignupInputs />}

        <p className="rights">© 2024 Inc. All rights reserved.</p>
      </BaseCard>
    </StyledAuthForm>
  );
}

const LoginInputs = () => {
  return (
    <>
      <div className="inputs">
        <InputWrapper>
          <Label>Email</Label>
          <TextInput type="email" placeholder="Enter your email" />
        </InputWrapper>
      </div>
      <div className="button-wrapper">
        <Button
          label="Continue"
          onClick={() => console.log('click')}
          width={'100%'}
        />
      </div>
    </>
  );
};

const StyledSignupInputs = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* max-width: 50%; */

  .sign-up-inputs-names {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8%;
  }
`;

const SignupInputs = () => {
  const navigate = useNavigate();

  return (
    <>
      <StyledSignupInputs>
        <div className="sign-up-inputs-names">
          <InputWrapper>
            <Label>First</Label>
            <TextInput type="text" placeholder="Enter your First name" />
          </InputWrapper>
          <InputWrapper>
            <Label>Last</Label>
            <TextInput type="text" placeholder="Enter your Last name" />
          </InputWrapper>
        </div>
        <InputWrapper>
          <Label>Email</Label>
          <TextInput type="email" placeholder="Enter your email" />
        </InputWrapper>

        <InputWrapper>
          <Label>Phone</Label>
          <TextInput type="phone" placeholder="Enter your mobile number" />
        </InputWrapper>
      </StyledSignupInputs>

      <div className="button-wrapper">
        <Button
          label="Continue"
          onClick={() => navigate('/signup')}
          width={'100%'}
        />
      </div>
    </>
  );
};
