import styled, { createGlobalStyle } from 'styled-components';
import { Outlet } from 'react-router-dom';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import Navbar from '../Navbar';
import Footer from '../Footer';

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
    /* font-family: 'League Spartan', sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 1.5;
    background-color: #f4f4f9;
    color: #333;

  }

  #root{
    min-height:100vh;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    font-family: inherit;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }
`;

const StyledLayout = styled.main`
  margin: 0 auto;
`;

export default function Layout() {
  return (
    <SnackbarProvider>
      <GlobalStyle />
      <Navbar />
      <StyledLayout>
        <Outlet />
      </StyledLayout>
      <Footer />
    </SnackbarProvider>
  );
}
