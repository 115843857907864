import React, { useState } from 'react';
import styled from 'styled-components';

const ToggleWrapper = styled.div`
  display: flex;
  width: 200px;
  height: 40px;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid #333;
  cursor: pointer;
  position: relative;
  opacity:0.85;
`;

const ToggleOption = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (active ? '#F0F0F2' : '#ddd')};
  /* color: ${({ active }) => (active ? '#fff' : '#333')}; */
  color: black;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  font-size: 12px;
`;

const ToggleIndicator = styled.div`
  position: absolute;
  top: 0;
  left: ${({ active }) => (active ? '50%' : '0')};
  width: 50%;
  height: 100%;
  background-color: #333;
  border-radius: 16px;
  transition: left 0.3s;
  z-index: 0;
`;

export default function ToggleButton({ onToggle, isLogIn }) {
  return (
    <ToggleWrapper onClick={onToggle}>
      <ToggleIndicator active={!isLogIn} />
      <ToggleOption active={isLogIn} style={{ zIndex: 1 }}>
        Sign In
      </ToggleOption>
      <ToggleOption active={!isLogIn} style={{ zIndex: 1 }}>
        Sign Up
      </ToggleOption>
    </ToggleWrapper>
  );
}
