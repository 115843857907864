import React from 'react';
import { useGLTF, MeshTransmissionMaterial } from '@react-three/drei';
import { useThree, useFrame } from '@react-three/fiber';
import { Mesh } from 'three';
import { useControls } from 'leva';

const NODE_NAME: string = 'SOL_M_Solana_0';
const MODEL_LOCATION = `${window.location.origin}/assets/models/solana.glb`;

export default function SolanaModel() {
  const { nodes } = useGLTF(MODEL_LOCATION);
  const { viewport, size } = useThree();

  const meshRef = React.useRef<Mesh>(null);

  // Rotate the model continuously
  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y += 0.004;
    }
  });

  const materialProps = useControls({
    thickness: { value: 1.7, min: 0, max: 3, step: 0.5 },
    roughness: { value: 0, min: 0, max: 1, step: 0.1 },
    transmission: { value: 1, min: 0, max: 1, step: 0.1 },
    ior: { value: 0.6, min: 0, max: 3, step: 0.1 },
    chromaticAberration: { value: 0.92, min: 0, max: 1 },
    backside: { value: true },
    textDistance: { value: 0.5, min: -2, max: 3 },
    textColor: { value: '#2d8fd2' },
  });

  const node = nodes[NODE_NAME];
  if (!node) {
    console.error(`Node ${NODE_NAME} not found.`);
    return null;
  }
  const meshNode = node as unknown as Mesh;

  const modelScale = Math.min(viewport.width / 7, viewport.height / 7);

  return (
    <group scale={[modelScale, modelScale, modelScale]}>
      {meshNode.geometry && meshNode.material && (
        <mesh
          ref={meshRef}
          geometry={meshNode.geometry}
          material={meshNode.material}
          rotation={[90, 180, 180]}
        >
          <MeshTransmissionMaterial {...materialProps} />
        </mesh>
      )}
    </group>
  );
}
