import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

import Button from '../Button';

const StyledNavbar = styled.div<{ isScrolled: boolean }>`
  display: flex;
  justify-content: center;
  background-color: black;
  padding: 10px;
  background-color: #040303;
  padding: ${({ isScrolled }) => (isScrolled ? '5px' : '20px')};
  transition: padding 0.3s ease;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

const Container = styled.div`
  position: relative;
  /* width: 1400px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4%; //move this later

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  white-space: nowrap;
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

const Logo = styled.img`
  height: 26px;
  opacity: 0.86;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 12px;
  }
`;

const List = styled.ul`
  display: flex;
  gap: 26px;
  list-style: none;
  align-items: center;
  height: 100%;
`;

const ListItem = styled.li`
  cursor: pointer;
  color: white;
  opacity: 0.6;
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
`;

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthPage = location.pathname.includes('/auth');
  const isCareers = location.pathname.includes('/careers');

  const hideNavbar = isAuthPage;

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 450);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return !hideNavbar ? (
    <StyledNavbar isScrolled={isScrolled}>
      <Container>
        <Logo
          src="/assets/images/logo-full-horizontal-text-white.png"
          onClick={() => navigate('/')}
        />

        <Links>
          <List>
            <ListItem
              onClick={
                isCareers
                  ? () => navigate('/')
                  : () => scrollToSection('home-mission')
              }
            >
              Mission
            </ListItem>
            <ListItem
              onClick={() =>
                isCareers ? navigate('/') : scrollToSection('home-tokenomics')
              }
            >
              Tokenomics
            </ListItem>
            <ListItem
              onClick={() =>
                isCareers ? navigate('/') : scrollToSection('home-learn-more')
              }
            >
              Learn More
            </ListItem>
            <ListItem onClick={() => navigate('/careers')}>Careers</ListItem>
            {process.env.REACT_APP_SHOW_PRIV_ROUTES == 'true' && (
              <Button
                label="Sign up"
                type="button"
                onClick={() => navigate('/auth')}
                bg="#2d8fd2"
              />
            )}
          </List>
        </Links>
      </Container>
    </StyledNavbar>
  ) : (
    <></>
  );
};

export default Navbar;
