// import './App.css';
import '../../utils/translations/i18n';
import styled from 'styled-components';
import { Leva } from 'leva';
import Hero from './components/Hero';
import MissionVision from './components/MissionVision';
import Tokenomics from './components/Tokenomics';
import LearnMore from './components/LearnMore';
import WorkWithUs from './components/WorkWithUs';

import { SHOW_3D_GUI } from '../../consts/misc';

const StyledHomePage = styled.div`
  position: relative;
  text-align: center;
  min-height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  color: white;
  background: url('./assets/images/bg.jpeg');
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;

  &::-webkit-scrollbar {
    display: none;
  }
`;

function HomePage() {
  return (
    <StyledHomePage className="page-home">
      <Leva hidden={!SHOW_3D_GUI} />
      <Hero />
      <MissionVision />
      <Tokenomics />
      <WorkWithUs />
      <LearnMore />
    </StyledHomePage>
  );
}

export default HomePage;

// bluebag.app
// /web.bluebag.app