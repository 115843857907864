import { FaDiscord, FaReddit, FaTelegramPlane, FaYoutube, FaLinkedin, FaInstagramSquare } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { AiFillTikTok } from "react-icons/ai";


const getSocialNetworksDetails = () => {

    return {
        TELEGRAM: {
            displayName: "Telegram",
            link: 'https://t.me/+7PZFnXhMI1gyZWE0',
            color: '#0088cc',
            icon: FaTelegramPlane
        },
        DISCORD: {
            displayName: "Discord",
            link: 'https://discord.gg/gDkjMUV2b2',
            color: '#7289da',
            icon: FaDiscord
        },
        REDDIT: {
            displayName: "Reddit",
            link: 'https://www.reddit.com/r/BLUEBAG',
            color: '#ff4500',
            icon: FaReddit
        },
        X: {
            displayName: "X",
            link: 'https://x.com/BlueBagDotApp',
            color: '#FFF',
            icon: FaXTwitter
        },
        YOUTUBE: {
            displayName: "Youtube",
            link: 'https://www.youtube.com/@bluebagapp',
            color: '#FF0000',
            icon: FaYoutube
        },
        LINKEDIN: {
            displayName: "LinkedIn",
            link: 'https://www.linkedin.com/company/bluebagapp/',
            color: '#0077B5',
            icon: FaLinkedin
        },
        INSTAGRAM: {
            displayName: "Instagram",
            link: 'https://www.instagram.com/tedtoken/',
            color: '#F56040',
            icon: FaInstagramSquare
        },
        TIKTOK: {
            displayName: "TikTok",
            link: 'https://www.tiktok.com/@BLUEBAG.APP',
            color: '#ff0050',
            icon: AiFillTikTok
        },
    };

}

export default getSocialNetworksDetails