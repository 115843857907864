import React from 'react';
import styled from 'styled-components';

const StyledTextInput = styled.input`
  padding: 18px;
  background-color: #e8e6e6;
  border: none;
  border-radius: 5px;

  @media only screen and (max-width: 450px) {
    padding: 14px;
  }

`;

type TextInputPropsType = any; //change

export default function TextInput(props: TextInputPropsType) {
  const { placeholder, type, name, value, onChange } = props;

  return (
    <StyledTextInput
      placeholder={placeholder}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
    ></StyledTextInput>
  );
}
