import React from 'react';
import styled from 'styled-components';
import {useLocation } from 'react-router-dom';

import emailValidator from 'email-validator';

import getSocialNetworksDetails from '../../utils/misc/getSocialNetworksDetails';

import TextInput from '../forms/TextInput';
import Button from '../Button';

const socialNetworksDetails = getSocialNetworksDetails();

const FooterContainer = styled.footer`
  background-color: #000000;
  /* padding: 20px; */
  padding: 40px 6%; //move this later
  box-sizing: border-box;

  @media only screen and (max-width: 500px) {
    padding: 30px 6%; //move this later
  }
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const FooterLogo = styled.div`
  img {
    height: 26px;

    @media only screen and (max-width: 700px) {
      height: 22px;
    }

    @media only screen and (max-width: 500px) {
      /* height: 20px; */
    }
  }
`;

const SocialIcons = styled.div`
  a {
    margin: 0 10px;
    color: inherit;

    svg {
      width: 26px;

      @media only screen and (max-width: 700px) {
        width: 20px;
      }

      @media only screen and (max-width: 500px) {
        width: 16px;
      }
    }
  }
`;

const FooterDivider = styled.div`
  border-top: 1px solid #e0e0e0;
  margin: 20px 0;
  opacity: 0.15;
`;

const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: normal;
  padding: 20px;
`;

const FooterInfo = styled.div`
  display: flex;
  gap: 60px;
  text-align: justify;

  .footer-column-socials {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
    justify-content: center;
    text-align: center;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  h4 {
    margin-bottom: 10px;
    color: #ffffff;

    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 5px;
  }

  a {
    text-decoration: none;
    color: #7e7e7e;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }
`;

const FooterSubscription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  white-space: nowrap;

  @media only screen and (max-width: 850px) {
    display: none;
  }

  h4 {
    margin-bottom: 16px;
    color: #ffffff;

    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
  }

  input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }

  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

const FooterSeparator = styled.div`
  width: 50%;
  height: 1px;
  background-color: #fff;
  margin: 20px;
  align-self: center;
  opacity: 0.15;
`;

const FooterBottomCenter = styled.div`
  opacity: 0.35;
  p {
    margin: 0;
    color: #ffffff;
    font-size: 12px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 10px;
  }
`;

const Form = styled.form`
  width: 160px;
  display: flex;
  flex-direction: column;
  /* gap: 25px; */
  /* 
  @media only screen and (max-width: 768px) {
    width: 300px;
  } */
`;

export default function Footer() {
  const formRef = React.useRef<HTMLFormElement | null>(null);

  const [emailValue, setEmailValue] = React.useState<string>('');
  const [isButtonDisabled, setButtonDisabled] = React.useState<boolean>(true);

  const location = useLocation();

  const shouldShowFooter = location.pathname == '/';

  React.useEffect(() => {
    setButtonDisabled(!emailValidator.validate(emailValue));
  }, [emailValue]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const formData = {
        access_key: process.env.REACT_APP_WEB3_FORMS_API_KEY,
        email: emailValue,
      };

      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      console.log('result');
      console.log(result);

      if (response.ok) {
        alert('Form submitted successfully');
        setEmailValue('');
      } else {
        alert('Error sending form');
      }
    } catch (error) {
      alert('Error sending form');
    }
  };

  return shouldShowFooter ? (
    <FooterContainer>
      <FooterTop>
        <FooterLogo>
          <img
            src="/assets/images/logo-full-horizontal-text-white.png"
            alt="Logo"
          />
        </FooterLogo>
        <SocialIcons>
          {Object.values(socialNetworksDetails).map((social: any) => {
            return (
              <a href={social.link} target="_blank" rel="noopener noreferrer" key={social.link}>
                <social.icon size={24} color={social.color} textColor="white" />
              </a>
            );
          })}
        </SocialIcons>
      </FooterTop>
      <FooterDivider />
      <FooterBottom>
        <FooterInfo>
          <FooterColumn className="footer-column-socials">
            <h4>Social Networks</h4>
            <ul>
              {Object.values(socialNetworksDetails)
                .slice(0, 4)
                .map((social) => {
                  return (
                    <li>
                      <a
                        href={social.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {social.displayName}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </FooterColumn>
          <FooterColumn className="footer-column-socials">
            <h4 style={{ opacity: 0 }}>Social Networks</h4>

            <ul>
              {Object.values(socialNetworksDetails)
                .slice(4, 9)
                .map((social) => {
                  return (
                    <li key={`social-detail-${social.link}`}>
                      <a
                        href={social.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {social.displayName}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </FooterColumn>
          <FooterColumn>
            <h4>Resources</h4>
            <ul>
              {/* <li>
                <a href="#">Apply to be a Beta Tester</a>
              </li> */}
              <li>
                <a href="#">Whitepaper (Coming Soon)</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Terms & Conditions</a>
              </li>
              <li>
                <a href="#">Contact Us</a>
              </li>
            </ul>
          </FooterColumn>
          <FooterSubscription>
            <h4>Subscribe to our newsletter</h4>
            {/* <input type="email" placeholder="Enter your email" /> */}

            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              action={'https://api.web3forms.com/submit'}
              method={'POST'}
            >
              <TextInput
                type={'hidden'}
                name={'access_key'}
                value={process.env.REACT_APP_WEB3_FORMS_API_KEY}
              />

              <TextInput
                placeholder="add your email"
                name="email"
                type="Enter your email"
                onChange={(evt: any) => setEmailValue(evt.target.value)}
                value={emailValue}
              />
              {/* <button type="button">Apply</button> */}

              <Button
                type="submit"
                disabled={isButtonDisabled}
                label="Subscribe"
                onClick={() => console.log('test')}
              ></Button>
            </Form>
          </FooterSubscription>
        </FooterInfo>
        <FooterSeparator />
        <FooterBottomCenter>
          <p>&copy; 2024 Inc. All rights reserved.</p>
        </FooterBottomCenter>
      </FooterBottom>
    </FooterContainer>
  ) : (
    <></>
  );
}
